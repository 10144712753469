import React from "react";

import { Grid, Box } from "@material-ui/core";

import ProductionGrid from './ProductionGrid'
import ProductionGridA from './ProductionGridA'
import ProductionGridB from './ProductionGridB'
import ProductionGridC from './ProductionGridC'

function ProductionGridLayout({ performances }) {
    const gridSize = 6; // count of performances in a grid 

    let arrayOfGrids = [];
    let tmpPerformances = [];
    let countOfGrid = 0
    let lastUsedGrid = 3
    performances.map((performance, index) => {
      if(index !== 0 && index % gridSize === 0) {
        if(lastUsedGrid === 1) {
          arrayOfGrids.push(<ProductionGridB key={countOfGrid} performances={tmpPerformances} />);
          tmpPerformances = []
          countOfGrid++;
          lastUsedGrid = 2;
        } else if(lastUsedGrid === 2) {
          arrayOfGrids.push(<ProductionGridC key={countOfGrid} performances={tmpPerformances} />);
          tmpPerformances = []
          countOfGrid++;
          lastUsedGrid = 3;
        } else if(lastUsedGrid === 3) {
          arrayOfGrids.push(<ProductionGridA key={countOfGrid} performances={tmpPerformances} />);
          tmpPerformances = []
          lastUsedGrid = 1;
          countOfGrid++;
        }
      }
      tmpPerformances.push(performance);
      return null
    })

    if(tmpPerformances.length === gridSize) {
      arrayOfGrids.push(<ProductionGridA key={countOfGrid} performances={tmpPerformances} />);
    } else if(tmpPerformances.length !== 0) {
      arrayOfGrids.push(<ProductionGrid key={countOfGrid} performances={tmpPerformances} />);
    }

    return (
      <Box className="performances-wrapper push-top-dbl push-btm-dbl">
        <Grid container spacing={3} className="performances">
          {arrayOfGrids}
        </Grid>
      </Box>
    )
}

export default ProductionGridLayout;