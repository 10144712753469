import React from "react";

import { Grid, Hidden } from "@material-ui/core";
import Performance from './Performance'

export default function ProductionGrid({ performances }) {

    return (
        <>
            {performances.length === 1 &&
                <>
                    <Hidden mdDown>
                        <Grid item xs={12} md={6} lg={4}></Grid>
                    </Hidden>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={4}>
                                <Performance boxType={3} performance={performances[0]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={12} md={6} lg={4}></Grid>
                    </Hidden>
                </>
            }
            {performances.length === 2 &&
                <>
                    <Hidden mdDown>
                        <Grid item xs={12} md={6} lg={4}></Grid>
                    </Hidden>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={4}>
                                <Grid container spacing={3} className="fullHeight">
                                    <Grid item xs={6} className="no-padd-btm">
                                        <Performance boxType={4} performance={performances[0]}/>
                                    </Grid>
                                    <Grid item xs={6} className="no-padd-btm">
                                        <Performance boxType={4} performance={performances[1]}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={12} md={6} lg={4}></Grid>
                    </Hidden>
                </>
            }
            {performances.length === 3 &&
                <>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={4}>
                                <Performance boxType={3} performance={performances[0]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={8}>
                                <Performance boxType={2} performance={performances[1]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={4}>
                                <Performance boxType={3} performance={performances[2]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
            {performances.length === 4 &&
                <>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={4}>
                                <Performance boxType={3} performance={performances[0]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={8}>
                                <Performance boxType={2} performance={performances[1]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={4}>
                                <Grid container spacing={3} className="fullHeight">
                                    <Grid item xs={6} className="no-padd-btm">
                                        <Performance boxType={4} performance={performances[2]}/>
                                    </Grid>
                                    <Grid item xs={6} className="no-padd-btm">
                                        <Performance boxType={4} performance={performances[3]}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
            {performances.length === 5 &&
                <>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={4}>
                                <Performance boxType="3" performance={performances[0]}/>
                            </Grid>
                            <Grid item xs={4} className="no-padd-btm">
                                <Grid container spacing={3} className="fullHeight">
                                    <Grid item xs={6} className="no-padd-btm">
                                        <Performance boxType={4} performance={performances[1]}/>
                                    </Grid>
                                    <Grid item xs={6} className="no-padd-btm">
                                        <Performance boxType={4} performance={performances[2]}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={12}>
                                <Performance boxType={1} performance={performances[3]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container direction='column' spacing={3} className="performances-column">
                            <Grid item xs={8}>
                                <Performance boxType={2} performance={performances[4]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}