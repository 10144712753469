import React, { memo, useEffect, useState, useContext } from "react";
import { CategoryCtx } from "../../context/CategoryCtx";
import { getPerformances } from "../../context/PerformancesCtx";
import { Text, LanguageCtx } from '../../context/LanguageCtx';
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import ProductionMenu from '../../components/ProductionMenu';
import ProductionGridLayout from '../../components/ProductionGridLayout';

import Grid from "@material-ui/core/Grid";

// Animation on scroll
import AOS from "aos";
import "aos/dist/aos.css";

import '../../assets/css/production.scss'

function ProductionPage() {
  const { getText } = useContext(LanguageCtx);
  const { selectedCategory, setSelectedCategory } = useContext(CategoryCtx);
  const [performances, setPerformances] = useState([]); 

  useEffect(() => {
    AOS.init({
      duration: 1200
    });
  }, []);

  useEffect(() => {
    setPerformances(getPerformances(selectedCategory))
  }, [getPerformances, selectedCategory])

  return (
    <Layout headerType={1}>
      <SEO title={getText("production")} />
      <Grid container id="production-page" justify="center" className="page content-light content-padd-top padd-btm">
        <Grid item lg={9} xs={11}>
          <h3 className='page-title push-top-dbl padd-top-dbl'>
            <span className="underline-effect-wrapper">
              <span className='hand underline-effect' onClick={() => setSelectedCategory(null)} onKeyDown={() => setSelectedCategory(null)}><Text tid="production"/></span>
            </span>
          </h3>
          <Grid container justify="center" className="padd-btm push-btm-hlf padd-top-hlf push-top">
            <Grid item xs={11}>
              <ProductionMenu selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            </Grid>
          </Grid>
          <ProductionGridLayout performances={performances}/>
        </Grid>
      </Grid>
    </Layout>
  );
}
export default memo(ProductionPage);