import React, { useContext } from "react";
import { Textfit } from 'react-textfit';
import { navigate } from 'gatsby';

import Image from './Image';
import { UPCOMING, getMinTheatrePremiere } from '../assets/data';

import Box from "@material-ui/core/Box";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { Text } from '../context/LanguageCtx';

const maxFS = 50; // maximal font size
// box has 4 types:
//     - 0: undefined box
//     - 1: large box
//     - 2: medium box
//     - 3: small box
//     - 4: extra small box

const getPremiereAndDerniere = (theaters) => {
    let actualStatus = 0
    const years = [9999, 0]
    theaters.map((theater) => {
        if(theater.status === 1)
            actualStatus = 1
        else if(theater.status === 2 && actualStatus !== 1) 
            actualStatus = 2
        else if(theater.status === 3)
            actualStatus = 3
        
        let minTheatrePremiere = getMinTheatrePremiere(theater)
        if(minTheatrePremiere < years[0])
            years[0] = minTheatrePremiere
        if(theater.derniere > years[1])
            years[1] = theater.derniere
        
        return theater
    })
    
    if(actualStatus === 0)
        return (
            <>
                {years[0]} - {years[1]}
                <div className="t-red">
                    <FiberManualRecordIcon />
                </div>
            </>
        )            
    else if(actualStatus === 1) {
        return (
            <>
                {years[0]}
                <div className="t-green">
                    <FiberManualRecordIcon />
                </div>
                <Text tid="category.current"/>
            </>
        )
    } else if(actualStatus === 2) {
        return (
            <>
                {years[0]}
                <div className="t-gray">
                    <FiberManualRecordIcon />
                </div>
                <Text tid="category.expected"/>
            </>
        )
    } else {
        return (
            <>
                Festival
                <div className="t-green">
                    <FiberManualRecordIcon />
                </div>
                <Text tid="category.current"/>
            </>
        )
    }
}

function Performance({ performance, boxType}) {

    const handleSelectPerformance = (performance) => {
        if(performance.unique !== UPCOMING) {
            navigate(`/production/category/?id=${encodeURIComponent(performance.unique)}`)
        }
    }

    return (
        <Box className='performance-item-wrapper t-bold t-white overflow pos-rel fullHeight hand' data-aos="fade-up">
            <Box className={`performance-item performance-item-${boxType} fullSize`} onClick={() => handleSelectPerformance(performance)}>
                <Image className="pos-abs performance-image fullSize" alt={`${performance.unique}`} filename={`${performance.unique}-1.jpg`} />
                <Box className='overlay o-low'/>
                <Box className="pos-abs fullSize performance-name t-center padd text-fit">
                    <Textfit max={maxFS}>
                        <Text tid={`${performance.unique}.prefix`}/> <Text tid={`${performance.unique}.name`}/>
                    </Textfit>
                </Box>
                <Box className='pos-abs fullSize performance-overlay t-center'>
                    <Box className='overlay'/>
                    <Box className='middle overlay-text-wrapper'>
                        <Box className='overlay-title t-red push-btm-hlf'>
                            <Text tid={`${performance.unique}.prefix`}/> <Text tid={`${performance.unique}.name`}/>
                        </Box>
                        <Box className='overlay-info'>
                            {performance.unique === UPCOMING ?
                                <>
                                    <Text tid={`${performance.unique}.premiere`}/>
                                    <div className="t-gray">
                                        <FiberManualRecordIcon />
                                    </div>
                                    <Text tid="category.expected"/>
                                </>
                            :
                                getPremiereAndDerniere(performance.theaters)
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Performance