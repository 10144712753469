import React from "react";

import Grid from "@material-ui/core/Grid";
import Performance from './Performance'

export default function ProductionGridC({ performances }) {
    
    return (
        <>
            <Grid item xs={12} md={6} lg={4}>
                <Grid container direction='column' spacing={3} className="performances-column">
                    <Grid item xs={4}>
                        <Performance boxType={3} performance={performances[0]}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Performance boxType={2} performance={performances[1]}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Grid container direction='column' spacing={3} className="performances-column">
                    <Grid item xs={8}>
                        <Performance boxType={2} performance={performances[2]}/>
                    </Grid>
                    <Grid item xs={4} className="no-padd-btm">
                        <Grid container spacing={3} className="fullHeight">
                            <Grid item xs={6} className="no-padd-btm">
                                <Performance boxType={4} performance={performances[3]}/>
                            </Grid>
                            <Grid item xs={6} className="no-padd-btm">
                                <Performance boxType={4} performance={performances[4]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Grid container direction='column' spacing={3} className="performances-column">
                    <Grid item xs={12}>
                        <Performance boxType={1} performance={performances[5]}/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}