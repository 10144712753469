import React from 'react';
import { Text } from '../context/LanguageCtx';
import Grid from "@material-ui/core/Grid";

import { MUSICALS_CATEGORY, BALLET_OPERA_CATEGORY, SHOW_CATEGORY, THEATRE_DANCE_CATEGORY } from '../assets/data';

export default function ProductionMenu({selectedCategory, setSelectedCategory}) {

    return (
        <Grid container>
            <Grid item md={3} xs={12} className="production-category-btn-wrapper hand underline-effect-wrapper" onClick={() => setSelectedCategory(MUSICALS_CATEGORY)}>
                <span className={`underline-effect ${selectedCategory === MUSICALS_CATEGORY ? "active" : ""}`}><Text tid="production.musical"/></span>
            </Grid>
            <Grid item md={3} xs={12} className="production-category-btn-wrapper hand underline-effect-wrapper" onClick={() => setSelectedCategory(BALLET_OPERA_CATEGORY)}>
                <span className={`underline-effect ${selectedCategory === BALLET_OPERA_CATEGORY ? "active" : ""}`}><Text tid="production.balletopera"/></span>
            </Grid>
            <Grid item md={3} xs={12} className="production-category-btn-wrapper hand underline-effect-wrapper" onClick={() => setSelectedCategory(SHOW_CATEGORY)}>
                <span className={`underline-effect ${selectedCategory === SHOW_CATEGORY ? "active" : ""}`}><Text tid="production.show"/></span>
            </Grid>
            <Grid item md={3} xs={12} className="production-category-btn-wrapper hand underline-effect-wrapper" onClick={() => setSelectedCategory(THEATRE_DANCE_CATEGORY)}>
                <span className={`underline-effect ${selectedCategory === THEATRE_DANCE_CATEGORY ? "active" : ""}`}><Text tid="production.theatredance"/></span>
            </Grid>
        </Grid>
    );
}